<style lang="scss">
@import "~@/assets/css/var.scss";
.report-detail {
    padding-top: 40px;
    width: 1080px;
    margin: 50px auto;
    .clickable {
        cursor: pointer;
        &:hover {
            opacity: .7;
        }
    }
    &__head {
        display: flex;
        padding: 30px 100px;
        border-top: 1px solid #DCDFE6;
        border-bottom: 1px solid #DCDFE6;
        .img {
            width: 200px;
        }
        .look {
            margin-left: -35px;
            background: #ff852f;
            line-height: 20px;
            height: 20px;
            padding: 0 15px;
            color: #fff;
            border-radius: 1px 1px 10px 1px;
        }
        .panel {
            padding-left: 25px;
            flex: 1;
        }
        .name {
            padding-top: 10px;
            color: #333;
            font-size: 18px;
            line-height: 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: bold;
            margin-bottom: 20px;
        }
        .good {
            span {
                display: inline-block;
                background: $main;
                color: #fff;
                line-height: 24px;
                border-radius: 12px;
                width: 60px;
                text-align: center;
                margin-bottom: 30px;
                margin-right: 15px;
                i {
                    margin-right: 5px;
                    font-size: 10px
                }
            }
        }
        .el-col {
            color: #666;
            font-size: 14px;
        }
    }

    &__body {
        padding: 35px 0;
        border-bottom: 1px solid #DCDFE6;
        .title {
            font-size: 16px;
            i {
                padding-right: 5px;
            }
        }
        .content {
            font-size: 14px;
            padding: 25px 25px 0;
            white-space: pre-line;
        }
    }
}
.pay-dialog__desc {
  flex: 1;
  color: #333;
  text-align: center;
  .amount {
    font-weight: bold;
    font-size: 18px;
    span {
      font-size: 25px;
      color: $red;
    }
  }
  .money {
    font-size: 15px;
  }
  .desc {
    padding-top: 10px;
    font-size: 12px;
    color: #777;
  }
}
</style>

<template>
    <div class="report-detail">
        <div class="report-detail__head">
            <img class="img" src="@/assets/img/report.png" alt="">
            <div class="look">关注度：{{form.countVisit || 0}}</div>
            <div class="panel">
                <p class="name">{{form.title}}</p>
                <p class="good">
                    <span><i class="icon icon-good"></i>推荐</span>
                    <span class="clickable" @click="downloadFile"><i :class="downloadLoading ? 'el-icon-loading' : 'el-icon-download'"></i>下载</span>
                </p>
                <el-row>
                    <el-col :span="12">产品类型：{{form.category}}</el-col>
                    <el-col :span="12" v-if="form.fees === '会员免费'">收费标准：{{form.fees}}</el-col>
                    <el-col :span="12" v-else>收费标准：￥{{form.price}}</el-col>
                    <el-col :span="12">报告类型：{{form.reportCategory}}</el-col>
                    <el-col :span="12">文件页码：{{form.countPage}}</el-col>
                    <el-col :span="12">浏览次数：{{form.countVisit}}</el-col>
                    <el-col :span="12">下载次数：{{form.countDown}}</el-col>
                </el-row>
            </div>
        </div>
        <div class="report-detail__body">
            <p class="title"><i class="el-icon-document"></i>报告导读</p>
            <p class="content">{{form.description}}</p>
        </div>
        <div class="report-detail__body">
            <p class="title"><i class="el-icon-document"></i>内容简介</p>
            <p class="content">{{form.content}}</p>
        </div>

        <ts-dialog :show.sync="payDialog" title="支付页面" hide-footer width="450px">
            <div v-if="payDialog" class="flex flex-center">
                <div class="pay-dialog__desc">
                    <p class="amount"><span>￥</span>{{form.price}}元</p>
                    <p class="money">{{$help.amountToChinese(form.price)}}</p>
                    <p class="desc">该报告下载需支付费用，请确认需求后支付</p>
                </div>
                <canvas id="pay"></canvas>
            </div>
        </ts-dialog>
    </div>
</template>

<script>
import QRCode from 'qrcode'
let timerWechat = null;
export default {
    data() {
        return {
            visitId: new Date().getTime(),
            pageId: 'reportDetail',
            pageName: '报告详情',

            form: {},
            downloadLoading: false,
            payDialog: false,
        }
    },
    created() {
        this.$store.commit('loading')
        this.$help.socket.send(this)
        this.$api.get(`report/aimReportFile/findAimReportFileById`, this.$route.params, {'Visit-Id': this.$help.getVisitId(this.visitId)})
            .then(res => {
                this.form = res.data
            })
            .finally(() => {
                this.$store.commit('loaded')
            })
    },
    destroyed() {
        this.clearTimer()
    },
    watch: {
        payDialog(val) {
            if (!val) {
                this.clearTimer()
            }
        }
    },
    methods: {
        clearTimer() {
            clearTimeout(timerWechat)
            setTimeout(() => {
                clearTimeout(timerWechat)
            }, 3000)
            setTimeout(() => {
                clearTimeout(timerWechat)
            }, 13000)
        },
        downloadFile() {
            if (this.downloadLoading) {
                return false
            }
            this.downloadLoading = true
            this.$api.get(`report/aimReportFile/downUrl?id=${this.$route.params.id}`)
                .then(res => {
                    if (res.code === '00000') {
                        if (res.data) {
                            window.open(res.data)
                        } else {
                            const json = JSON.parse(res.message)
                            this.payDialog = true
                            this.$nextTick(() => {
                                QRCode.toCanvas(document.getElementById("pay"), json.qrCode, {width: 150,height:150})
                                this.clearTimer()
                                this.watchOrder(json.order.id)
                            })
                        }
                    } else {
                        this.$store.commit('error', res.message)
                    }
                })
                .finally(() => {
                    this.downloadLoading = false
                })
        },
        watchOrder(id) {
            if (id) {
                this.$api.post(`sys/sysUserPayment/querySysUserPayOrder?id=${id}&orderType=report`, {
                    orderId: id,
                    orderType: 'report',
                })
                    .then(data => {
                        if (data.code === '00000') {
                            this.$store.commit('success', '支付成功')
                            setTimeout(() => {
                                window.location.reload();
                            }, 1000)
                        } else {
                            timerWechat = setTimeout(() => {
                                this.watchOrder(id)
                            }, 1000)
                        }
                    })
                    .catch(() => {
                        timerWechat = setTimeout(() => {
                            this.watchOrder(id)
                        }, 1000)
                    })
            }
        },
    },
}
</script>